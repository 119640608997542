import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse,  faBook,  faSchool,  faCode } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Contact from './Contact';

function App() {
  return (
    <div className="font-outfit">
      <div className="w-screen h-24 flex flex-row justify-center bg-zinc-900 p-4">
        <section id="header" className="w-full h-full flex flex-row justify-center items-center">
          <header className="w-full max-w-4xl h-full flex flex-row justify-center items-center">
            <nav className="w-full h-full grid grid-cols-2 sm:grid-cols-4 gap-4 px-4">
              <a href="#home" className="font-semibold text-base flex flex-row justify-center items-center text-white w-full h-12 rounded-lg outline-none border-transparent border-2 hover:border-white hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 hover:shadow hover:scale-95 transition-all ease-in-out duration-300">
                <FontAwesomeIcon icon={faHouse} className="mr-2" />
                Home
              </a>
              <a href="#about" className="font-semibold text-base flex flex-row justify-center items-center text-white w-full h-12 rounded-lg outline-none hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 border-transparent border-2 hover:border-white hover:shadow hover:scale-95 transition-all ease-in-out duration-300">
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                About
              </a>
              <a href="#skills" className="font-semibold text-base flex flex-row justify-center items-center text-white w-full h-12 rounded-lg outline-none hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 border-transparent border-2 hover:border-white hover:shadow hover:scale-95 transition-all ease-in-out duration-300">
                <FontAwesomeIcon icon={faSchool} className="mr-2" />
                Skills
              </a>
              <a href="#projects" className="font-semibold text-base flex flex-row justify-center items-center text-white w-full h-12 rounded-lg outline-none hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 border-transparent border-2 hover:border-white hover:shadow hover:scale-95 transition-all ease-in-out duration-300">
                <FontAwesomeIcon icon={faBook} className="mr-2" />
                Projects
              </a>
            </nav>
          </header>
        </section>
      </div>
      

      <section id="home" className="min-h-screen w-screen flex flex-col items-center bg-zinc-900 ">
        <div className="text-center p-10 py-10 flex flex-col items-center">
          <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-9xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600 py-2 font-semibold font-outfit">Fernando Soto Briceno</h2>
          <h3 className="text-xl sm:text-2xl text-white font-semibold py-10">Coder and Highschooler with a passion in <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600">math and astrophysics</span></h3>
          <div className="bg-white h-full w-full flex flex-col lg:flex-row items-stretch justify-between p-5 rounded-xl shadow-black shadow-2xl">
            <div id="info" className="flex-1 mb-10 lg:mb-0 lg:mr-10 flex flex-col items-center p-5 bg-blue-500 rounded-lg shadow-sm shadow-black">
              <h2 className="font-semibold text-2xl sm:text-3xl lg:text-4xl mb-5 text-white">Information About Me</h2>
              <p className="text-base sm:text-lg leading-relaxed text-white max-w-[500px] text-center">
                I'm a 16 year old Junior that has a deep passion for programming and math, I've only started to code in my Freshman year. 
                My main coding language is Python, but I've started to learn web development and other languages such as Java, ReactJS, Javascript, and more.
                I have a great interest in math, physics, and computer science. Learning algorithms, calculus, and astronomy is one of my favorite things to do.
                I've made multiple small scale projects but my goal is to make medium to large scale projects that have impact. My most current project is a full functioning school GPA calculator.
              </p>
            </div>
            <div id="image" className="sm:h-80 lg:h-[415px] lg:w-[550px] bg-gradient-to-l from-cyan-400 to-blue-600 py-10 rounded-lg shadow-sm shadow-black flex items-center justify-center">
              <img src="components/photo2.jpg" alt="Profile" className="rounded-xl rotate-3 h-52 sm:h-64 lg:h-[375px] shadow-2xl"></img>
            </div>
          </div>
        </div>
      </section>


      <section id = "about" className="min-h-screen w-screen flex flex-col items-center bg-zinc-900">
        <h1 className="text-5xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600 py-10 font-semibold font-outfit">About Me</h1>
        <div className=" w-full p-10 grid grid-cols-1 gap-4 sm:grid-cols-2">

          <div className="bg-blue-400 rounded-lg shadow-xl min-h-[250px] text-center p-2">
            <h3 className="w-full text-center text-white font-bold text-3xl">Coding</h3>
            <div className="flex flex-grow items-center justify-center w-full">
              <p className="text-white text-xl max-w-[90%]">
                I began coding in freshman year with AP Computer Science Principles, initially struggling with JavaScript. 
                Joined a coding club and competed in Lockheed Martin's CodeQuest in April 2023.
                Over the summer, I mastered Python through Leetcode and Lockheed Martin's CodeQuest Academy, excelling in Python projects.
                In my Sophomore year, I would do AP CSA and learn Java, and eventually start to do web development.    
              </p>
            </div>
          </div>

          <div className="bg-blue-500 rounded-lg shadow-xl min-h-[250px] text-center p-2">
            <h3 className="w-full text-center text-white font-bold text-3xl">Web Development</h3>
            <div className="flex flex-grow items-center justify-center w-full">
              <p className="text-white text-xl max-w-[90%]">
                I started learning HTML and CSS in early 2024 in my Sophmore year, as I had won 4th place in my FBLA club for States, meaning I was going to Nationals. 
                I made a medium-scale project with HTML, CSS, and Javascript which was a functional GPA calculator that had a database and validated user input (Which you can see in my Github).
                Learning Web Development has been very fun. I've started to create medium scale projects, and I'm also designing a website for a non-profit student organization.
              </p>
            </div>
          </div>

          <div className="bg-blue-600 rounded-lg shadow-xl min-h-[250px] text-center p-2">
            <h3 className="w-full text-center text-white font-bold text-3xl">Interests</h3>
            <div className="flex flex-grow items-center justify-center w-full">
              <p className="text-white text-xl max-w-[90%]">
                I've always been interested in math, and now I'm learning calculus and physics. Recently as of my Freshman year, coding and computer science has been a new interest of mine.
                Astronomy is also one of my interests, as I want to become an astrophysicist and incorporate coding with my career in the future.
                Overall, computer science has been a heavy influence on my interests now as coding has made my skills better and learn new languages.
              </p>
            </div>
          </div>

          <div className="bg-blue-700 rounded-lg shadow-xl min-h-[250px] text-center p-2">
            <h3 className="w-full text-center text-white font-bold text-3xl">Activities</h3>
            <div className="flex flex-grow items-center justify-center w-full">
              <p className="text-white text-xl max-w-[90%]">
                I have attended a competitive programming event called CodeQuest by Lockheed Martin. For my practices, I've done CodeQuest Academy, Codewars, and Leetcode where I've solved more than 200 problems combined.
                I've made many small scale projects such as a planet simulator, a word counter, a program which shows current NBA game stats, and more. Some of my projects are in my github, but majority are kept in my folders.
              </p>
            </div>
          </div>
        </div>
        <h3 className="text-2xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600 py-2 font-semibold">My Links</h3>
        <div className="min-w-56 min-h-16 flex flex-row justify-around items-center">
          <a href="https://github.com/fxrchis" target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg w-24 h-24 p-2 flex flex-row justify-center items-center text-5xl outline-none text-blue-600 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out shadow-lg shadow-black">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href="https://leetcode.com/u/ferchis76/" target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg w-24 h-24 p-2 flex flex-row justify-center items-center text-5xl outline-none text-blue-600 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out shadow-lg shadow-black">
            <FontAwesomeIcon icon={faCode}/>
          </a>
        </div>
      </section>

      <section id="skills" className="min-h-screen w-screen flex flex-col items-center bg-zinc-900 p-5">
        <h1 className="text-3xl sm:text-4xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600 py-10 font-semibold">My Skills</h1>
        <div className="w-full sm:w-5/6 md:w-4/6 bg-blue-600 p-5 rounded-2xl grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 shadow-black shadow-2xl text-center">

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out ">
            <img src="components/html.svg" alt="HTML" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">HTML</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/css.svg" alt="CSS" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">CSS</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/tailwind.svg" alt="Tailwind" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">Tailwind</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/react.svg" alt="ReactJS" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">ReactJS</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/js.svg" alt="Javascript" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">Javascript</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/python.svg" alt="Python" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">Python</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/java.svg" alt="Java" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">Java</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/firebase.svg" alt="Firebase" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">Firebase</h3>
          </div>

          <div className="flex flex-col justify-around items-center bg-white p-3 rounded-2xl border-blue-800 border-4 shadow-blue-950 shadow-lg hover:scale-95 transition-all duration-300 ease-in-out">
            <img src="components/mongo.svg" alt="MongoDB" className="h-16 sm:h-20 md:h-24"></img>
            <h3 className="font-semibold text-lg sm:text-xl">MongoDB</h3>
          </div>

        </div>
      </section>


      <section id="projects" className="min-h-screen w-screen flex flex-col items-center bg-zinc-900">
        <h1 className="text-3xl sm:text-4xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600 py-10 font-semibold">My Projects</h1>
        <div id="outer" className="w-11/12 sm:w-5/6 flex flex-col gap-4 bg-white p-4 sm:p-6 rounded-2xl shadow-black shadow-2xl">

          <div className="bg-blue-600 min-h-[250px] w-full rounded-3xl shadow-2xl overflow-hidden flex flex-col sm:flex-row justify-evenly p-2 gap-4 sm:gap-0">
            <div className="w-full sm:w-3/4 flex items-center justify-center">
              <img src="components/proj 1.png" className="object-cover rounded-2xl w-full" />
            </div>

            <div className="w-full sm:w-52 flex flex-col justify-around items-center text-center">
              <h3 className="text-white font-semibold text-lg sm:text-2xl">GPA Calculator</h3>
              <h2 className="text-white font-light text-lg sm:text-normal italic">Computer Only</h2>
              <p className="text-white font-semibold text-sm sm:text-lg">A full on functioning GPA Calculator meant for a school which includes a database, login and signup system, and multiple pages for students to browse.</p>
              
              <a href="https://fxrchis.github.io/gpa-calculator/#" target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg w-12 h-12 sm:w-24 sm:h-24 p-2 flex flex-row justify-center items-center text-2xl sm:text-5xl outline-none shadow-blue-950 shadow-2xl text-blue-600 hover:bg-blue-800 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </div>
          </div>

          <div className="bg-blue-600 min-h-[250px] w-full rounded-3xl shadow-2xl overflow-hidden flex flex-col sm:flex-row justify-evenly p-2 gap-4 sm:gap-0">
            <div className="w-full sm:w-3/4 flex items-center justify-center">
              <img src="components/InternTracker.png" className="object-cover rounded-2xl w-full" />
            </div>

            <div className="w-full sm:w-52 flex flex-col justify-around items-center text-center">
              <h3 className="text-white font-semibold text-lg sm:text-2xl">InternTracker</h3>
              <p className="text-white font-semibold text-sm sm:text-lg">A website which tracks internships and scholarships around the US, helping students finding scholarship programs and internships.</p>
              <a href="https://interntracker.org/home" target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg w-12 h-12 sm:w-24 sm:h-24 p-2 flex flex-row justify-center items-center text-2xl sm:text-5xl outline-none shadow-blue-950 shadow-2xl text-blue-600 hover:bg-blue-800 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out">
                <FontAwesomeIcon icon={faCode} />
              </a>
            </div>
          </div>
        </div>
      </section>


      <section id="contacts" className="min-h-screen w-screen flex flex-col items-center bg-zinc-900">
        <h1 className="text-5xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-600 py-10 font-semibold">Contacts</h1>

        <Contact></Contact>
      </section>

    </div>
    
  );
}

export default App;
