import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
    const [result, setResult] = React.useState("");
  
    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "a2def166-a544-459f-8908-a54b2396112c");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Email Sent!");
        event.target.reset();
        setTimeout(() => {
          setResult("");
        }, 3000);
      } else {
        console.log("Error", data);
        setResult(data.message);
        setTimeout(() => {
          setResult("");
        }, 3000);
      }
    };
  
    return (
        <div className="bg-blue-700 w-11/12 sm:w-4/6 h-auto sm:h-96 flex flex-col sm:flex-row justify-evenly items-center rounded-2xl p-4 sm:p-0">
            <div className="w-full sm:w-1/2 h-full flex flex-col justify-evenly items-center gap-4 sm:gap-0">
                <div className="flex flex-col justify-evenly items-center h-24 w-full sm:w-96">
                <h1 className="text-white font-semibold text-xl sm:text-2xl">Email</h1>
                <div className="flex flex-row items-center">
                    <span className="bg-white rounded-lg w-10 h-10 sm:w-12 sm:h-12 p-2 flex flex-row justify-center items-center text-xl sm:text-2xl outline-none shadow-blue-950 shadow-2xl text-blue-600 hover:bg-blue-800 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out">
                    <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <p className="text-white p-2 text-sm sm:text-base">fernandobusiness9999@gmail.com</p>
                </div>
                </div>

                <div className="flex flex-col justify-evenly items-center h-24 w-full sm:w-96">
                <h1 className="text-white font-semibold text-xl sm:text-2xl">LinkedIn</h1>
                <a href="https://www.linkedin.com/in/fernando-soto-briceno-440346313/" target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg w-10 h-10 sm:w-12 sm:h-12 p-2 flex flex-row justify-center items-center text-xl sm:text-2xl outline-none shadow-blue-950 shadow-2xl text-blue-600 hover:bg-blue-800 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out">
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
                </div>

                <div className="flex flex-col justify-evenly items-center h-24 w-full sm:w-96">
                <h1 className="text-white font-semibold text-xl sm:text-2xl">GitHub</h1>
                <a href="https://github.com/fxrchis" target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg w-10 h-10 sm:w-12 sm:h-12 p-2 flex flex-row justify-center items-center text-xl sm:text-2xl outline-none shadow-blue-950 shadow-2xl text-blue-600 hover:bg-blue-800 hover:text-white hover:scale-95 transition-all duration-300 ease-in-out">
                    <FontAwesomeIcon icon={faGithub} />
                </a>
                </div>
            </div>
            
            <div className="h-full w-full sm:w-1/2 flex flex-col justify-evenly items-center overflow-hidden mt-4 sm:mt-0">
                <form onSubmit={onSubmit} className="flex flex-col justify-around items-center h-full w-full bg-blue-50 rounded-2xl border-blue-700 border-8 p-4 sm:p-0 gap-4">
                  <input type="text" name="name" placeholder="Name" className="w-full sm:w-72 h-12 p-2 rounded shadow-lg outline-none" required />
                  <input type="email" name="email" placeholder="Email" className="w-full sm:w-72 h-12 p-2 rounded shadow-lg outline-none" required />
                  <textarea name="message" placeholder="Message" className="w-full sm:w-5/6 h-24 p-2 rounded shadow-lg outline-none appearance-none" required></textarea>
                  <button type="submit" className="bg-white w-1/2 sm:w-1/3 h-10 rounded-xl font-normal shadow-lg border-2 border-blue-700 hover:bg-blue-700 hover:scale-95 hover:text-white transition-all ease-in-out duration-300">Submit Form</button>
                  <span>{result}</span>
                </form>
            </div>
        </div>

    );
  }